<template>
  <div class="mainform">
    <div class="form">
      <el-form ref="form" :model="formData" :rules="formRules" label-width="100px">
        <div class="col col8">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input v-model="formData.oldPassword"></el-input>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="formData.newPassword" show-password></el-input>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="确认密码" prop="verifyPassword">
            <el-input type="password" v-model="formData.verifyPassword" show-password></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePassword',
  data () {
    var verifyPass = (rule, value, callback) => {
      if (value !== this.formData.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      formData: {},
      time: 0,
      formRules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码必须为大于六位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,32}$/, message: '密码必须包含数字及字母', trigger: 'blur' }
        ],
        verifyPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, message: '密码必须为大于六位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,32}$/, message: '密码必须包含数字及字母', trigger: 'blur' },
          { validator: verifyPass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
      .form {
        .el-form{
          :deep(.el-form-item){
                .el-form-item__content{
                    .el-input {
                        .el-input-group__append{
                            line-height: 32px;
                            padding: 0px 25px;
                            font-size: 13px;
                        }
                    }
                }
          }
        }
      }
</style>
